.timeline-row-container {
    /* margin: 3vh; */
    /* background-color: cadetblue; */
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    padding-left: 2vh;
    padding-right: 2vh;
}

.timeline-row-container:hover {
    border-radius: 50px;
    background-color: rgb(245, 244, 244);
    /* border-style: solid; */
}

.timeline-row-time {
    /* flex-grow: 1; */
    /* background-color: blueviolet; */
    text-align: right;
    width: 100px;
    margin-right: 10vh;
    font-size: large;
}

.timeline-row-node {
    background-color: lightgray;
    border-color: black;
    border-style: solid;
    border-width: 1px;
    /* margin: 5px; */
    width: 40px;
    height: 40px;
    position: relative;
    top: -10px;
    left: -20px;
    border-radius: 25px;
}

.timeline-row-meal-name {
    /* flex-grow: 1; */
    /* background-color: orange; */
    text-align: left;
    width: 200px;
    margin-left: 10vh;
    font-size: large;
}

.timeline-row-bar {
    width: 1px;
    height: 80px;
    position: relative;
    top: 30px;
    border-width: 0.01em;
    border-style: solid;
    border-color: black;
}

.hide-bar {
    border-style: none;
}

.is-past-font {
    color: lightgray;
}

.is-past-background {
    background-color: rgb(115, 147, 179);
}
