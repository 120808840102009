@import url("https://fonts.googleapis.com/css?family=Lora:400,700|Montserrat:300");

.landing-page-container {
  /* For some reason this container needs a border to maintain the size */
  /* border-style: solid; */
  /* border-color: rgba(240, 248, 255, 0); */
  /* background: rgb(115, 147, 179); */
  height: 100%;
  width: 100%;
  padding: 15%;
  padding-top: 10%;
}

.hero-container {
  max-width: 500px;
  /* border-radius: 5px; */
  /* border-right-style: solid;
  border-bottom-style: solid;
  border-color: lightgrey;
  border-width: 1px; */
  /* padding: 20px; */
}

.headline {
  /* height: 100%; */
  width: 100%;
  /* color: white; */
  text-align: left;
  font-size: xx-large;
  /* padding: 20%; */
  font-family: "Inter", sans-serif;
  font-optical-sizing: auto;
  font-weight: 600;
  font-style: normal;
  font-variation-settings: "slnt" 0;
  margin-bottom: 15px;
  line-height: 35px;
}

.description {
  text-align: left;
  font-size: medium;
  color: grey;
}

.call-to-action {
  margin-top: 20px;
  width: 200px;
  height: 40px;
  text-align: center;
  background-color: rgb(115, 147, 179);
  color: white;
  border-radius: 5px;
  line-height: 40px;
}

.calendar-icon {
  width: 50px;
  height: auto;
}
