.meal-timeline-container {
    display: flex;
    /* flex-direction: row; */
    flex-flow: row wrap;
    /* background-color: gold; */
    /* overflow: auto; */
    /* margin: 10vh;
    margin-top: 0vh; */
    justify-content: space-evenly;
    height: 100%;
    width: 100%;
}

.meal-timeline-content-left {
    /* background-color: lightgoldenrodyellow; */
    margin: 5vh;
    height: 300px;
    max-width: 500px;
    min-width: 200px;
    flex-grow: 1;
}

.meal-timeline-content-right {
    /* background-color: lightblue; */
    margin: 5vh;
    margin-left: 10vh;
    margin-right: 10vh;
    height: 300px;
    /* width: 600px; */
    max-width: 800px;
    min-width: 300px;
    flex-grow: 2;
    border-color: black;
    border-width: 1px;
    border-style: solid;
}

.meal-timeline-content-right h3 {
    margin-top: 3vh;
}
