.notification-modal-container {
  /* Location */
  position: relative;
  margin-bottom: 5px;

  /* Dimensions */
  width: 200px;
  height: 50px;

  /* Formatting: Center vertically */
  display: flex;
  align-items: center;
  justify-content: center;

  /* Add style */
  background-color: lightgrey;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);

  transition: transform 0.3s ease-in-out;
}

.noti-content-container {
  padding-left: 20px;
  padding-right: 20px;

  color: black;
  font-size: x-small;
  line-height: normal;
  text-align: left;
}

.noti-modal-column {
  /* Format divs */
  display: flex;
  flex-direction: column;
  width: 100%;
}

.noti-modal-close-button-container {
  position: absolute;
  top: 5px;
  right: 5px;
  /* transform: translate(-50%, -50%);
  transition: opacity 0.3s ease-in-out; */
}

.hide {
  transform: translateX(150%);
}

.progress-bar-container {
  width: 100%;
  position: absolute;
  bottom: 0;
}
