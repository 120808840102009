.gallery-card-container {
    width: 220px;
    height: 333px;
    margin: 10px 10px;
    /* background-color: black; */
    /* border-style: solid; */
    /* margin-bottom: 20px; */
}

.gallery-card-image-container {
    width: 100%;
    height: 225px;
    border-style: solid;
    border-radius: 5px;
    border-width: 1px;
    border-color: lightgray;
    background-image: url("../../../../resources/1.jpeg");
    background-size: 220px 225px;
}

.gallery-card-name {
    font-size: larger;
    text-align: left;
    margin: 10px 0px;
    /* background-color: blue; */
}

.gallery-new-card-name {
    font-size: larger;
    text-align: center;
    margin: 20px 0px;
    font-weight: bolder;
}

.gallery-card-description {
    width: 220px;
    font-size: small;
    text-align: left;
    margin: 5px 0px;
    color: grey;
}
