.dashboard-container {
    /* background-color: pink; */
    height: 1000px;
    /* overflow: auto; */
}

.dashboard-header-container {
    /* background-color: plum; */
    width: fit-content;
    margin: auto;
    margin-top: 10vh;
    margin-bottom: 5vh;
}
