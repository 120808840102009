.mealplan-detail-container {
    margin: 5% 10%;
    height: fit-content;
    /* border-style: solid; */
    /* border-color: greenyellow; */
}

.mealplan-detail-header {
    /* border-style: solid;
    border-color: purple; */
}

.mealplan-detail-header h1 {
    font-size: xx-large;
    font-weight: lighter;
}

.mealplan-detail-content-container {
    margin: 5vh 0vh;
    /* background-color: blueviolet; */
    height: 1000px;
    width: 100%;
}

.mealplan-detail-description {
    /* border-style: solid;
    border-color: red; */
    margin: 0vh 1vh;
    text-align: left;
    margin-bottom: 5vh;
    flex-grow: 3;
}

.mealplan-detail-description h4 {
    font-weight: bolder;
    font-size: large;
    /* background-color: aqua; */
}

.mealplan-detail-description p {
    /* background-color: blueviolet; */
}

.mealplan-detail-calendar-container {
    /* background-color: green; */
    display: flex;
    height: 100%;
    width: 100%;

    flex-wrap: wrap;
    justify-content: space-between;
}

.mealplan-detail-calendar-column-1 {
    flex-grow: 1;
    display: inline-block;
    /* background-color: pink; */
    /* border-style: solid; */
    min-width: 160px;
    max-width: 160px;
    /* flex-direction: column; */
}

.mealplan-detail-calendar-column-header {
    /* display: inline-block; */
    margin: 1vh;
    /* padding: 2vh 1vh; */
    /* font-weight: bolder; */
    font-family: Roboto, Helvetica, Arial, sans-serif;
    /* font-size: large; */
    font-size: 11px;
    font-weight: 500;
    background-color: rgb(115, 147, 179);
    height: 25px;
    padding: 4px;
    color: white;
}

.meal-card-container {
    display: inline-block;
    width: 100%;
}

.mealplan-detail-peripherals {
    margin: 1vh;
    /* background-color: aqua; */
    display: flex;
    flex-wrap: wrap;
}

.mealplan-detail-peripheral-buttons {
    flex-grow: 1;
    /* background-color: aqua; */
    display: flex;
    justify-content: flex-end;
}

.mealplan-detail-calendar-column-2 {
    /* flex-direction: column; */
    flex-wrap: nowrap;
    /* background-color: blue; */
    width: 100%;
    height: 100%;
}

.mealplan-detail-create-plannedmeal-button {
    display: inline-block;
    /* background-color: blue; */
    /* border-style: solid; */
    margin: 1vh;
    border-radius: 30px;
    /* border-style: solid; */
    width: 60px;
    height: 60px;
    font-size: xx-large;
    color: rgb(115, 147, 179);
    border-color: rgb(115, 147, 179);
    padding: 14px 0;
    text-align: center;
}

.mealplan-detail-set-favorite-button {
    display: inline-block;
    /* background-color: blue; */
    /* border-style: solid; */
    margin: 1vh;
    border-radius: 30px;
    /* border-style: solid; */
    width: 60px;
    height: 60px;
    font-size: xx-large;
    color: rgb(115, 147, 179);
    border-color: rgb(115, 147, 179);
    padding: 13px 0;
    text-align: center;
}

.mealplan-detail-set-favorite-button:hover {
    color: white;
    background-color: rgb(115, 147, 179);
}

.mealplan-detail-create-plannedmeal-button:hover {
    color: white;
    background-color: rgb(115, 147, 179);
}

.mealplan-detail-shopping-list-button {
    display: inline-block;
    margin: 1vh;
    border-radius: 30px;
    /* border-style: solid; */
    width: 60px;
    height: 60px;
    font-size: x-large;
    color: rgb(115, 147, 179);
    border-color: rgb(115, 147, 179);
    padding: 19px 0px;
    padding-right: 2px;
    text-align: center;
}

.mealplan-detail-shopping-list-button:hover {
    background-color: rgb(115, 147, 179);
    color: white;
}

.mealplan-detail-delete-mealplan-button {
    display: inline-block;
    margin: 1vh;
    border-radius: 30px;
    /* border-style: solid; */
    width: 60px;
    height: 60px;
    font-size: x-large;
    color: rgb(218, 40, 40);
    border-color: rgb(218, 40, 40);
    padding: 19px 15px;
    text-align: center;
}

.mealplan-detail-delete-mealplan-button:hover {
    color: white;
    background-color: rgb(218, 40, 40);
}
