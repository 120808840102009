.App {
    text-align: center;
    height: 100vh;
}

.version {
    opacity: 0.5;
    position: fixed;
    bottom: 2%;
    right: 2%;
}
