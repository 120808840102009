.meal-page-container {
    font-family: "Montserrat", sans-serif;
    font-weight: 300;
    text-transform: uppercase;
    height: fit-content;
    position: relative;
    padding: 50px;
    padding-top: 25px;
}

.meal-search-page-controls {
    width: 300px;
    margin: 0px auto;
    display: flex;
    justify-content: space-between;
}

.page-prev-button {
    font-size: x-large;
}

.page-prev-button:hover {
    cursor: pointer;
}

.page-next-button {
    font-size: x-large;
}

.page-next-button:hover {
    cursor: pointer;
}
