.result-container {
    background-color: whitesmoke;
    margin: 20px;
    padding: 15px;
    box-shadow: 0 2.8px 2.2px rgba(0, 0, 0, 0.034),
        0 6.7px 5.3px rgba(0, 0, 0, 0.048), 0 12.5px 5px rgba(0, 0, 0, 0.06),
        0 22.3px 17.9px rgba(0, 0, 0, 0.072),
        0 41.8px 33.4px rgba(0, 0, 0, 0.006), 0 10px 80px rgba(0, 0, 0, 0.12);
}

.result-container:hover {
    transition-duration: 250ms;
    background-color: rgba(128, 128, 128, 0.548);
    color: white;
}
