.edit-button {
    border: none;
    background-color: white;
    outline: none;
}

.edit-button:active {
    outline: none;
    border: none;
}

.edit-label-input {
    height: fit-content;
    width: 700px;
    max-width: 700px;
    min-width: 700px;
    min-height: 40px;
    max-height: 200px;
}
