.gallery-container {
    max-width: 1200px;
    height: 100%;
    margin: auto;
    /* background-color: aquamarine; */
    display: block;
}

.gallery-cards-container {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    margin: auto;
    /* margin-top: 80px; */
    /* background-color: aliceblue; */
    justify-content: center;
}

.gallery-title {
    text-align: center;
    /* background-color: aliceblue; */
    margin: 15px;
    margin-top: 60px;
    font-size: x-large;
    font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS",
        sans-serif;
}
