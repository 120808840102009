.mealplan-detail-meal-card {
    /* padding: 5px 0vh; */
    /* border-style: solid; */
    /* border-color: lightgray;
    border-radius: 5px;
    border-width: 1px; */
    /* margin: 1vh 0vh; */
    color: black;
    font-size: 12px;
    display: inline-block;
    width: 100%;
}

.mealplan-detail-meal-time {
    /* padding: 0vh 1vh; */
    margin: auto;
    padding-left: 8px;
    display: inline-block;
    font-size: small;
    text-align: left;
    width: 100%;
    color: grey;
    font-style: italic;
}

.mealplan-detail-meal-name {
    text-align: left;
    /* padding: 1vh 1vh; */
    padding-left: 8px;
    font-size: small;
    border-width: 1px;
    display: inline-block;
    width: 100%;
}

.mealplan-detail-meal-preference-name {
    text-align: left;
    /* padding: 1vh 1vh; */
    padding-left: 8px;
    font-size: small;
    border-width: 1px;
    display: inline-block;
    width: 100%;
    color: lightgrey;
}

.mealplan-detail-meal-name-missing {
    margin-top: 1vh;
    padding: 1vh 1vh;
    font-size: small;
    border-width: 1px;
    display: inline-block;
    width: 100%;
    min-height: 60px;
}

.mealplan-detail-meal-name:hover {
    color: blue;
    text-decoration: underline;
}

.meal-card-container {
    display: flex;
    width: 100%;
}

.plannedmeal-card-image {
    width: 50px;
    height: 50px;
    /* margin: 5px;
    margin-top: 3px; */
    border-style: solid;
    border-radius: 5px;
    border-width: 1px;
    border-color: lightgray;
    /* background-image: url("https://picsum.photos/220/333"); */
}

.plannedmeal-card-top-row {
    display: flex;
    justify-content: space-between;
}

.plannedmeal-card-delete:hover {
    color: red;
}

.plannedmeal-card-edit {
    margin-right: 5px;
}

.plannedmeal-card-edit:hover {
    color: grey;
}

.meal-card-details {
    width: 100%;
}
