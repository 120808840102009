/* @import url("https://fonts.googleapis.com/css?family=Lora:400,700|Montserrat:300"); */

nav {
    /* padding: 0px 30px; */
    /* padding-top: 10px; */

    display: flex;
    /* justify-content: space-between; */
    /* align-items: center; */
    /* min-height: 7vh; */
    background: rgb(115, 147, 179);
    color: white;
    /* position: fixed; */
    width: 100%;
    max-height: 48px;
    height: 48px;
}

nav h2 {
    /* margin-top: 25px;
    margin-left: 25px; */
}

.nav-links {
    width: 30%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    list-style: none;
    font-family: "Montserrat", sans-serif;
    /* font-weight: 300; */
    font-size: small;
    text-transform: uppercase;
}

li {
    /* white-space: nowrap;
    padding-left: 10px;
    padding-right: 10px; */
}
li:hover {
    color: lightgray;
}
