.search-bar-container {
    max-width: 575px;
    min-width: 250px;
    margin: auto;
    display: flex;
    /* justify-content: space-between; */
}

.search-bar-text-field {
    width: 100%;
    height: 50px;
    border-radius: 5px;
    border-style: solid;
    border-color: grey;
    border-width: 1px;
    text-align: left;
}

.search-bar-magnifying-glass {
    width: 20px;
    height: 20px;
    font-size: x-large;
    position: relative;
    top: 10px;
    left: 15px;
}

.search-bar-create-button {
    margin: auto;
    /* background-color: aquamarine; */
    width: 50px;
    height: 50px;
    border-radius: 25px;
    border-style: solid;
    border-color: grey;
    border-width: 1px;
}

.search-bar-input {
    background-color: transparent;
    /* background-color: red; */
    color: black;
    outline: none;
    border: none;
    font-size: 25px;
    position: relative;
    top: 10px;
    left: 25px;
    width: 90%;
    margin-bottom: 25px;
}

.search-bar-input::placeholder {
    color: rgb(155, 155, 155);
}
